var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.isLoading)?_c('section',[_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{staticClass:"text-subtitle-1 text-center",attrs:{"cols":"12"}},[_vm._v(" Mohon tunggu ")]),_c('v-col',{staticClass:"text-subtitle-1 text-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1)],1)],1):(_vm.isError)?_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-center red--text"},[_vm._v(_vm._s(_vm.$_strings.common.ERROR_MESSAGE))])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-large":"","icon":""},on:{"click":_vm.fetchData}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-reload ")])],1)]}}])},[_c('span',[_vm._v("Reload Halaman")])])],1)],1):_c('section',[_c('h4',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.$_strings.companyRegistration.COMPANY_REGISTRATION))]),_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"item-key":"companyAbbreviation","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatStatus(item.status))+" ")]}},{key:"item.statusApproval",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.statusApproval ? _vm.formatStatus(item.statusApproval) : '-')+" ")]}},{key:"item.actions",fn:function(ref){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":""},on:{"click":_vm.routeNavigate}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-file-find ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.VIEW_DETAIL))])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }