<template>
  <v-container fluid>

    <section v-if="isLoading">
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-subtitle-1 text-center"
          cols="12"
        >
          Mohon tunggu
        </v-col>
        <v-col
          class="text-subtitle-1 text-center"
          cols="12">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </section>
    <v-row v-else-if="isError" class="mt-5">
      <v-col cols="12">
        <h1 class="text-center red--text">{{$_strings.common.ERROR_MESSAGE}}</h1>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="fetchData"
              x-large
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon large>
                mdi-reload
              </v-icon>
            </v-btn>
          </template>
          <span>Reload Halaman</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <section v-else>
      <h4 class="mb-5">{{$_strings.companyRegistration.COMPANY_REGISTRATION}}</h4>
      <v-data-table
        :headers="headers"
        :items="itemsTable"
        class="elevation-1 table"
        item-key="companyAbbreviation"
        hide-default-footer
      >
        <template v-slot:[`item.status`]="{item}">
          {{formatStatus(item.status)}}
        </template>
        <template v-slot:[`item.statusApproval`]="{item}">
          {{item.statusApproval ? formatStatus(item.statusApproval) : '-'}}
        </template>
        <template v-slot:[`item.actions`]="{}">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="routeNavigate"
                color="primary"
                small
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-file-find
                </v-icon>
              </v-btn>
            </template>
            <span>{{$_strings.common.VIEW_DETAIL}}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </section>
  </v-container>
</template>

<script>
import { statusActionWorkflow } from '@/helper/commonHelpers';

export default {
  name: 'status-registration',
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      isError: false,
      isLoading: true,
      headers: [
        {
          text: this.$_strings.companyInformation.COMPANY_NAME_INPUT_LABEL,
          value: 'companyName',
          class: 'primary white--text',
        },
        {
          text: this.$_strings.companyInformation.ALIAS,
          value: 'companyAbbreviation',
          class: 'primary white--text',
        },
        {
          text: this.$_strings.companyInformation.STATUS_DOCUMENT,
          value: 'status',
          class: 'primary white--text',
        },
        {
          text: this.$_strings.companyInformation.STATUS_APPROVE,
          value: 'statusApproval',
          class: 'primary white--text',
        },
      ],
      itemsTable: [],
    };
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.isError = false;
      this.$_services.approval.getStatusRegisterCompany()
        .then((res) => {
          if (res.data) {
            if (res.data.statusApproval === 'DRAFT') {
              this.headers.push({
                text: this.$_strings.common.ACTION,
                value: 'actions',
                class: 'primary white--text',
              });
            }
            this.itemsTable.push(res.data);
            this.isLoading = false;
          } else {
            this.routeNavigate();
          }
        }).catch(() => {
          this.isError = true;
          this.isLoading = false;
        });
    },
    routeNavigate() {
      this.$router.push('/main/registration/company-registration');
    },
    formatStatus(status) {
      return statusActionWorkflow(status);
    },
  },
};
</script>

<style>

</style>
